.admin-layout {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  min-height: 100%;
  min-width: 100%;
}

.admin-layout-header {
  display: flex;
  min-width: 100%;
  color: white;
  background-color: #012a2d;
}
.admin-layout-side-panel {
  display: flex;
  width: 17%;
  color: white;
  background-color: #012a2d;
}

.admin-layout-side-panel-stretch {
  width: 100%;
  padding-right: 5%;
  padding-left: 10%;
}

.admin-layout-background {
  flex-grow: 1;
  display: flex;
  min-height: 87%;
  width: 83%;
  background-color: #f2e7dc;
}

.admin-layout-background-entity-fake {
  flex-grow: 1;
  display: flex;
  min-height: 87%;
  width: 83%;
  background-color: #a9d9d080;
}

.admin-layout-content {
  padding-top: 2%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  max-height: 98%;
  width: 95%;
}
