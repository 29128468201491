.benefit-description {
    text-transform: uppercase;
}

.benefit-card-description {
    color: black
}

.benefit-card-image {
    max-width: 20%;
}