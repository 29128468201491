.logo {
  width: 100%;
  display: flex;
  transition: all 0.25s ease-out;
  flex-direction: column;
  justify-content: center;
  fill: white;
}

.login-view {
  background-image: url("/public/images/fondo.png");
  color: white;
  background-size: cover;
  flex-grow: 1;
}

.login-video {
  overflow: hidden;
  max-width: 40%;
  max-height: 100vh;
}
