.logo {
    width: 100%;
    display: flex;
    transition: all .25s ease-out;
    flex-direction: column;
    justify-content: center;
    fill: white;
}

.register-view {
    background-image: url('/public/images/fondo.png');
    color: white;
    background-size: cover;
    flex-grow: 1;
}

.register-video {
    overflow: hidden;
    max-width: 40%;
    max-height: 100vh;
}