.video-background {
  width: 100%;
  position: absolute;
}

.logo {
  transition: all .25s ease-out;
  flex-direction: column;
  justify-content: center;
  fill: white;
}

.landing-content {
  top: 25%;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  position: absolute;
  color: #f1f1f1;
  background-color: #00575CD9;
}

.login-button {
  border-color: #07EFEF;
}

.login-button-text {
  text-align: left;
  color: white;
}