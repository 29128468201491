.logo {
  width: 100%;
  display: flex;
  transition: all 0.25s ease-out;
  flex-direction: column;
  justify-content: center;
  fill: white;
}

.success-view {
  background-image: url("/public/images/fondo.png");
  color: white;
}

.success-video {
  overflow: hidden;
  max-width: 100%;
}

.success-content {
  top: 5%;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  position: absolute;
  color: #f1f1f1;
}
