.landing-layout {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
}

.landing-layout-content {
}

.landing-layout-footer {
}